<template>
  <div class="home">
    <div class="hello">
      <div class="hero">

        <!-- Background Video -->
        <div class="video-background">
          <iframe
              src="https://www.youtube.com/embed/EIftfH1OxTc?autoplay=1&mute=1&loop=1&playlist=elZqwFcPZ9Q"
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
          ></iframe>
        </div>

        <!-- Overlay for Transparency -->
        <div class="overlay"></div>

        <!-- Navbar -->
        <nav class="navbar custom-nav navbar-expand-lg navbar-light">
          <div class="container">
            <router-link class="navbar-brand" to="/">
              <img src="/img/logo.png" alt="Logo" class="logo-img">
            </router-link>
            <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mr-auto"></ul>
              <form class="form-inline my-2 my-lg-0">
                <a class="btn btn-outline-success my-2 my-sm-0" href="#" data-toggle="modal" data-target="#start">
                  SisLife
                </a>
              </form>
            </div>
          </div>
        </nav>

        <!-- Hero Content -->
        <div class="hero-content">
          <div class="container text-center text-light py-5">
            <h2>De 1º a 4 de março</h2>
            <h4 class="mt-3">ACAMP ADVENTURE APOCALIPSE</h4>
            <p>
              "Bem-aventurado aquele que lê, e os que ouvem as palavras desta profecia, e guardam as coisas que nela estão
              escritas; porque o tempo está próximo." Ap 1:3
            </p>
            <ul class="list-unstyled mt-4">
              <li>✔️ Diversão garantida</li>
              <li>✔️ Atividades repletas de adrenalina</li>
              <li>✔️ Palavra e louvores a Deus</li>
            </ul>
            <p class="mt-4">
              🌟 Não perca a chance de participar deste evento transformador! As vagas são limitadas, então garanta já o
              seu lugar.
            </p>
            <a href="https://forms.gle/tFSHUeZKgM8sUfXg9" class="btn custom-btn btn-outline-info mt-3" target="_blank">
              📥 Inscreva-se agora
            </a>
            <p class="mt-4">🚀 Vamos juntos nessa aventura espiritual!</p>

            <a href="https://forms.gle/nfTmy12L2Hb5HKWh8" class="btn custom-btn btn-outline-success mt-3" target="_blank">
              Comprar Day Use
            </a>
          </div>
        </div>
      </div>
    </div>
    <Login />
  </div>
</template>


<script>
// @ is an alias to /src
import Login from "@/components/Login.vue";

export default {
  name: "home",
  components: {
    Login
  }
};
</script>

<style scoped>
/* Background Video */
.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  background-color: black;
}

.video-background iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 56.25vw;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

/* Overlay Styling */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Adjust transparency level here */
  z-index: 0;
}

/* Navbar Styling */
.navbar.custom-nav {
  background: transparent !important;
  z-index: 10;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 1rem 2rem;
}

.navbar-brand .logo-img {
  height: 100px;
  width: 100%;
}

.navbar .btn-outline-success {
  color: white;
  border-color: white;
}

.navbar .btn-outline-success:hover {
  background-color: white;
  color: black;
}

/* Hero Content */
.hero-content {
  position: relative;
  z-index: 1; /* Above the overlay */
  padding-top: 120px; /* Adjust for navbar height */
}

.hero-content h2,
.hero-content h4 {
  font-weight: bold;
}

.hero-content ul {
  list-style: none;
  padding: 0;
}

.hero-content li {
  margin: 10px 0;
  font-size: 18px;
}
</style>
